import React, { useRef, useState } from 'react'

import { Reveal } from "../utils/Reveal";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume, faEnvelope, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';


import '../static/css/index.css'
import '../static/css/contact.css'

const Contact = () => {
    const form = useRef();
    const [formFields, setFormFields] = useState({
        username: '',
        userphone: '',
        useremail: '',
        message: '',
    });

    const handleInputChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.target.value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const { username, userphone, useremail, message } = formFields;

        // Check if any field is empty
        if (!username || !userphone || !useremail || !message) {
            toast.error("Please fill in all fields.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
            return; // Stop further execution if fields are missing
        }

        form.current.username.value = username;
        form.current.userphone.value = userphone;
        form.current.useremail.value = useremail;
        form.current.message.value = message;

        console.log("Form current: ", form.current);

        // Send email if all fields are filled
        emailjs.sendForm('service_3vpr2jw', 'template_gr5srlg', form.current, 'xOD5zeNOwHeqMIN1J')
            .then((result) => {
                console.log(result.text);
                toast.success("Email sent successfully.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "dark",
                });
                setFormFields({
                    username: '',
                    userphone: '',
                    useremail: '',
                    message: '',
                });
            }, (error) => {
                console.log(error.text);
            });
    };
    return (
        <div className="main-container">
            <Helmet>
                <title>Contact Drive4Nena - Reliable Transportation & Logistics Services</title>
                <meta name="description" content="Get in touch with Drive4Nena for professional transportation services or to apply as a driver. We’re here to provide reliable solutions and answer your questions." />
                <meta name="keywords" content="contact Drive4Nena, transportation inquiries, logistics support, driver applications, reliable shipping solutions" />
                <link rel="canonical" href="/contact" />
            </Helmet>

            <Navbar />
            <div class="custom-shape-divider-top-1729358274">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="contact" id="contact">

                <div className="form-container">
                    <Reveal>
                        <h1>Contact</h1>
                    </Reveal>
                    <form ref={form} onSubmit={sendEmail} className="contact-form">
                        <Reveal>
                            <input
                                type="text"
                                name="username" required
                                placeholder="Enter your name"
                                className="small-field"
                                value={formFields.username}
                                onChange={handleInputChange}
                            />
                        </Reveal>
                        <Reveal>
                            <input
                                type="tel"
                                name="userphone" required
                                placeholder="Enter your phone number"
                                className="small-field"
                                value={formFields.userphone}
                                onChange={handleInputChange}
                            />
                        </Reveal>
                        <Reveal>
                            <input
                                type="email"
                                name="useremail" required
                                placeholder="Enter your e-mail"
                                className="small-field"
                                value={formFields.useremail}
                                onChange={handleInputChange}
                            />
                        </Reveal>
                        <Reveal>
                            <textarea
                                name="message" required
                                placeholder="Your message"
                                className="large-field"
                                value={formFields.message}
                                onChange={handleInputChange}
                            />
                        </Reveal>
                        <Reveal>
                            <input type="submit" value="Send" className="button-style button-cta" />
                        </Reveal>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                    </form>
                </div>
                <div className="get-in-touch-container">
                    <Reveal>
                        <h1>Get in Touch</h1>
                    </Reveal>
                    <Reveal>
                        <div className="git-section">
                            <div><FontAwesomeIcon icon={faPhoneVolume} className="icon" /></div>
                            <div>(708) 852-3170</div>
                        </div>
                    </Reveal>
                    <Reveal>
                        <div className="git-section">
                            <div><FontAwesomeIcon icon={faEnvelope} className="icon" /></div>
                            <div>admin@drive4nena.com</div>
                        </div>
                    </Reveal>
                    <Reveal>
                        <div className="git-section">
                            <div><FontAwesomeIcon icon={faMapLocationDot} className="icon" /></div>
                            <div className="sadsad">1 Westbrook Corporate Center Ste 300 Westchester, IL 60154</div>
                        </div>
                    </Reveal>

                    <Reveal>

                        <div className="map-container">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3494.5811746604845!2d-87.90792012356746!3d41.84690866736578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4bba31148c95%3A0x45a665c61d684fad!2s1%20Westbrook%20Corporate%20Center%2C%20Westchester%2C%20IL%2060154%2C%20USA!5e1!3m2!1sen!2srs!4v1731609848347!5m2!1sen!2srs"
                                width="100%"
                                height="100%"
                                style={{ border: "0" }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>

                    </Reveal>



                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;