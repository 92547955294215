import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../static/images/Logo_Black.png';
import '../static/css/index.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faPersonCircleQuestion, faCalendarXmark, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = ({ currentSection, onSectionChange }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isDoctor, setIsDoctor] = useState(false);
    const [initials, setInitials] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const location = useLocation();

    const navRef = useRef();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await axios.get("http://localhost:4000/check-auth", { withCredentials: true });
                setIsLoggedIn(response.data.isLoggedIn);
                setIsDoctor(response.data.isDoctor);
                setInitials(response.data.initials);
            } catch (error) {
                console.error("There was an error checking the auth status", error);
            }
        };

        checkAuthStatus();
    }, []);

    const handleLogout = async () => {
        try {
            await axios.get("http://localhost:4000/logout", { withCredentials: true });
            setIsLoggedIn(false);
            window.location.href = '/scheduling';
        } catch (error) {
            console.error("Error logging out", error);
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    };

    const linkStyle = (path) => ({
        color: location.pathname === '/about' ? 'var(--background-color)' : 'var(--textColor)',
        fontWeight: '700',
        textShadow: location.pathname !== '/' ? '0 0 1px rgba(0,0,0,0.5)' : 'none',
        transition: 'color 0.3s ease',
    });

    return (
        <header>
            <nav ref={navRef}>
                <div className="logo-div">
                    <Link to="/">
                        <img className="logo" src={Logo} alt="S Dline Logo" />
                    </Link>
                </div>
                <Link to="/" style={linkStyle('/')}>Home</Link>
                <Link to="/about" style={linkStyle('/about')}>About us</Link>
                <Link to="/testimonials" style={linkStyle('/testimonials')}>Testimonials</Link>
                <Link to="/contact" style={linkStyle('/contact')}>Contact</Link>
                <a
                    href="http://www.driver-reach.com/l/m27d2"
                    style={linkStyle('/apply')}
                    target="_blank" // Use target="_self" if you prefer the same tab
                    rel="noopener noreferrer" // For security reasons
                >
                    Apply
                </a>

                <button
                    className="nav-btn nav-close-btn"
                    onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>
            <button
                className="nav-btn"
                onClick={showNavbar}>
                <FaBars />
            </button>
        </header>
    );
};

export default Navbar;
